import { ContentTileProps } from "../components/elements/ContentTile/ContentTile";
import { Image, KontentContentTile } from "../types";
import { formatImages } from "./images";
import { formatLinks } from "./links";
import { getMultiChoice } from "./multichoice";
import { useRecentScientistTile } from "./useRecentScientistTile";

export function formatDate(start: string, end: string) {
  if (!start) {
    return;
  }

  const startDate = new Date(start);
  const endDate = end && new Date(end);

  function getTimeString(originalDate: Date) {
    const timeDiff = originalDate.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(originalDate.valueOf() + timeDiff);
    return adjustedDate.toLocaleString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  }

  function getDateString(originalDate: Date) {
    const timeDiff = originalDate.getTimezoneOffset() * 60000;
    const adjustedDate = new Date(originalDate.valueOf() + timeDiff);
    return adjustedDate.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
    });
  }

  let dateString = `${getDateString(startDate)}, ${getTimeString(startDate)}`;

  if (!endDate) {
    return dateString;
  }

  if (
    endDate &&
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate()
  ) {
    return (dateString += ` - ${getTimeString(endDate)}`);
  }

  return (dateString += ` - ${getDateString(endDate)}, ${getTimeString(
    endDate
  )}`);
}

export function formatContentTiles(
  content_tiles: KontentContentTile[]
): ContentTileProps[] | undefined {
  return content_tiles?.map((tile) => {
    let tileElements = tile?.elements;
    const overrideContent = getMultiChoice(tileElements?.override_content);

    switch (overrideContent) {
      case "scientist_of_the_day":
        tileElements = useRecentScientistTile() || tileElements;
        break;
      case "no":
        break;
    }

    let image = formatImages(tileElements?.image.value[0]?.elements).largeImage;

    const link = formatLinks(
      tileElements?.link_configuration__link?.modular_content
    )[0];
    const date =
      tile.elements.date_override.value ||
      formatDate(tileElements.start_date.value, tileElements.end_date.value);

    return {
      contentCategory: tileElements?.content_category_tax?.value[0]?.name,
      subhead: tileElements?.subhead.value,
      text: tileElements?.text,
      hideTextOnMobile:
        getMultiChoice(tileElements?.hide_text_on_mobile) === "yes",
      ctaHref: link?.href,
      ctaLabel: link?.label,
      ctaTarget: link?.target,
      ctaAriaLabel: link?.ariaLabel,
      image: image,
      date: date,
    };
  });
}
