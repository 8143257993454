import React, { useEffect, useRef, useState } from "react";
import * as styles from "./ContentTile.module.css";
import { Cta } from "../Cta/Cta";
//@ts-ignore
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { CustomRichTextElement } from "../CustomRichTextElement/CustomRichTextElement";
import { Image, KontentRichText } from "../../../types";
import { brandColors } from "../../../lib/color";
import { Link } from "../Link/Link";

export interface ContentTileProps {
  contentCategory?: string;
  subhead: string;
  text?: KontentRichText;
  image?: Image;
  ctaLabel?: string;
  ctaHref?: string;
  ctaTarget?: string;
  ctaAction?: "_blank" | "_self";
  ctaAriaLabel?: string;
  hideTextOnMobile?: boolean;
  tileWidth?: "full" | "oneThird" | "twoThirds";
  date?: string;
  hideCTA?: boolean;
  green?: boolean;
  overrideImage?: "scientist_of_the_day" | "no";
}

export const ContentTile: React.FC<ContentTileProps> = ({
  contentCategory,
  subhead,
  text,
  ctaLabel,
  ctaHref,
  ctaTarget,
  ctaAriaLabel,
  image,
  hideTextOnMobile,
  tileWidth,
  date,
  green,
  hideCTA,
}) => {
  const bp = useBreakpoint();
  const [bgStyle, setBgStyle] = useState<Partial<CSSStyleDeclaration>>(
    green ? { background: brandColors.green } : { background: brandColors.gray }
  );
  const tileWrapper = useRef(null);

  let overlayStyle = styles.overlay;

  if (!green) {
    overlayStyle =
      tileWidth === "full"
        ? `${styles.overlay} ${styles.leftOverlay}`
        : `${styles.overlay} ${styles.bottomOverlay}`;
  }

  const contentTileClassName =
    tileWidth === "oneThird" || tileWidth == "twoThirds"
      ? styles.contenttile + " " + styles[tileWidth]
      : styles.contenttile;

  const contentContainerClassName =
    tileWidth === "oneThird" || tileWidth == "twoThirds"
      ? styles.contentContainer + " " + styles[tileWidth]
      : styles.contentContainer;

  useEffect(() => {
    const width = tileWrapper?.current?.offsetWidth
      ? tileWrapper?.current?.offsetWidth
      : 1200;

    if (!green) {
      setBgStyle({
        backgroundImage: `url(${image?.item?.url}?w=${width})`,
      });
    }
  }, [image]);

  const renderTile = () => {
    return (
      <>
        <div className={overlayStyle}>
          <div className={contentContainerClassName}>
            <div className={styles.content}>
              {contentCategory && (
                <div className={styles.contentCategory}>{contentCategory}</div>
              )}
              <div>
                <h2>{subhead}</h2>
                {text && (!hideTextOnMobile || bp.l) && (
                  <div className={styles.text}>
                    <CustomRichTextElement content={text} />
                  </div>
                )}
                {date && (
                  <div className={styles.date}>{date.toUpperCase()}</div>
                )}

                {ctaLabel && ctaHref && !hideCTA && (
                  <div className={styles.ctaContainer}>
                    <Cta
                      href={ctaHref}
                      target={ctaTarget}
                      label={ctaLabel}
                      ariaLabel={ctaAriaLabel}
                      invert
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return ctaHref ? (
    <div
      className={styles.clickableTile + " " + contentTileClassName}
      style={bgStyle}
      ref={tileWrapper}
    >
      <Link to={ctaHref} target={ctaTarget}>
        {renderTile()}
      </Link>
    </div>
  ) : (
    <div className={contentTileClassName} style={bgStyle} ref={tileWrapper}>
      {renderTile()}
    </div>
  );
};
